import { SystemIcon } from 'legos/system-icon';
import { Anchor } from 'packages/react-nano-router';
import { createElement, FC, ReactNode } from 'react';
import { FontContainer, Button } from 'legos/typography';

import {
  StyledNavigationBar,
  NavigationBarTitle,
  BackIconLink,
  CloseIconLink,
  NavLinkIcon,
  DesktopBtnTxt
} from './navigation-bar.ccm.css';
import { useInPageFlowLayoutTheme } from './in-page-flow-layout.theme';
import { useIsReactNativeWebView } from 'packages/use-is-react-native-webview';

export interface NavigationBarProps {
  /**
   * children are styled as the NavigationBar title
   */
  children: ReactNode;

  /**
   * If specified, a left-justified icon wrapped in an anchor will be shown
   */
  backAnchor?: Anchor;

  /**
   * If specified, a right-justified icon wrapped in an anchor will be shown
   */
  cancelAnchor?: Anchor;
}

const DesktopTxtContainer = DesktopBtnTxt.as(Button);
const NavIcon = NavLinkIcon.as(SystemIcon);

export const NavigationBar: FC<NavigationBarProps> = ({
  children,
  backAnchor,
  cancelAnchor
}) => {
  const {
    backgroundColor,
    bottomBorderColor,
    titleColor
  } = useInPageFlowLayoutTheme();

  const BackBtnTxt = 'Back';

  return (
    <StyledNavigationBar.div
      $backgroundColor={backgroundColor}
      $bottomBorderColor={bottomBorderColor}
    >
      <BackAnchor backAnchor={backAnchor} BackBtnTxt={BackBtnTxt}>
        <NavIcon type="chevronLeft" color="primary" size={3} />
        <DesktopTxtContainer color="default">{BackBtnTxt}</DesktopTxtContainer>
      </BackAnchor>
      <NavigationBarTitle.span $titleColor={titleColor}>
        <FontContainer>{children}</FontContainer>
      </NavigationBarTitle.span>
      <CancelAnchor cancelAnchor={cancelAnchor} />
    </StyledNavigationBar.div>
  );
};

const BackAnchor: FC<{ backAnchor?: Anchor; BackBtnTxt: string }> = ({
  backAnchor,
  BackBtnTxt,
  children
}) => {
  const isReactNativeWebView = useIsReactNativeWebView();

  const rnBackHandler = () => {
    if (backAnchor?.href.indexOf('/account/gateway/r/dashboard') !== -1) {
      (window as any)?.ReactNativeWebView.postMessage(
        JSON.stringify({ goBack: true })
      );
    } else {
      history.back();
    }
  };

  return backAnchor ? (
    isReactNativeWebView ? (
      <BackIconLink.a
        onClick={() => rnBackHandler()}
        href="javascript:void(0);"
        title={BackBtnTxt}
      >
        {children}
      </BackIconLink.a>
    ) : (
      <BackIconLink.a {...backAnchor} title={BackBtnTxt}>
        {children}
      </BackIconLink.a>
    )
  ) : (
    <BackIconLink.span $empty />
  );
};

const CancelAnchor: FC<{ cancelAnchor?: Anchor }> = ({ cancelAnchor }) => {
  const isReactNativeWebView = useIsReactNativeWebView();

  return cancelAnchor ? (
    isReactNativeWebView ? (
      <CloseIconLink.a
        onClick={() => history.back()}
        href="javascript:void(0);"
        title="Cancel"
      >
        <NavIcon type="closeX" size={2} />
      </CloseIconLink.a>
    ) : (
      <CloseIconLink.a {...cancelAnchor} title="Cancel">
        <NavIcon type="closeX" size={2} />
      </CloseIconLink.a>
    )
  ) : (
    <CloseIconLink.span $empty />
  );
};
